.provinces-municipalities-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .provinces-municipalities-container label {
    margin-bottom: 5px;
  }

  .provinces-municipalities-container  div {

    display: flex;
    width: -webkit-fill-available;

  }
  
  .provinces-municipalities-container select {
    width: 100%;
    max-width: 300px;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .provinces-municipalities-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
  }
  
  .provinces-municipalities-container label {
    flex-basis: 100%;
    margin-bottom: 8px;
  }
  
  .provinces-municipalities-container select {
    /* flex-basis: calc(50% - 5px); */
    max-width: 300px;
    margin-right: 10px;
  }
  

  @media screen and (min-width: 800px) {
    .provinces-municipalities-container {
      flex-direction: row;

      align-items: center;
    }
  }

  @media screen and (max-width: 801px) {
    .provinces-municipalities-container {
      flex-direction: column;
    flex-wrap: wrap;

      align-items: flex-start;
    }
}
