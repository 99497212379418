

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.title {
  margin-top: 0;
}

.error-message {
  color: red;
  margin-top: 20px;
}

.no-results-message {
  margin-top: 20px;
}
