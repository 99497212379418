
.search-container input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 80%;
  }

  .search-button:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  

  .search-container label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  
  
  .search-container button {
    padding: 10px;
    margin-top: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  