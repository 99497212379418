/* .product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px;
}

.product-item {
  width: 25%;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  position: relative;
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
}

.product-item:hover {
  transform: translateY(-10px);
}

.product-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.product-details {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .product-list {
    flex-direction: column;
    align-items: center;
  }

  .product-item {
    width: 100%;
  }
}

.product-item img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.product-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  padding: 20px;
  box-sizing: border-box;
}

.product-item:hover .product-description {
  opacity: 1;
}

.product-description h3 {
  margin: 0;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 10px;
}

.product-description p {
  margin: 0;
  font-size: 1rem;
  text-align: center;
}

.product-item {
  position: relative;
}

img.product-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 40px;
  margin: 5px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.details-button, .link-button {
  border: none;
  background-color: #333;
  color: #fff;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}

.link-button {
  background-color: #F78F1E;
}

.details-button:hover, .link-button:hover {
  background-color: #555;
}

.search-bar{
  margin-bottom: inherit;
} */

/* ProductList.css */

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

.product-item:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.product-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 20px;
  text-align: center;
}

.store-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  max-height: 50px;
  max-width: 100px;
  z-index: 1;
}


.product-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}


.product-details {
  padding: 20px;
}

.product-name {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.product-price {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.product-store,
.product-shipping {
  margin: 5px 0;
}

.product-detail {
  margin: 10px 0;
}

.product-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.details-button,
.store-button {
  background-color: #0077cc;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  }
  
  .details-button:hover,
  .store-button:hover {
  background-color: #005ea3;
  }
  
  .details-button:active,
  .store-button:active {
  transform: translateY(1px);
  }